<template>
    <b-button :disabled="disabled || loading" @click="handleClick" class="app-button" :variant="setVariant" :size="size" :block="block" :class="[{ 'btn-link': link }, { disabled: disabled }]" pill>
        <slot name="loading">
            <b-spinner v-if="loading" class="mr-3" :class="{'app-btn-spinner-secondary': type == 'secondary', 'app-btn-spinner-all': type != 'secondary'}" small />
        </slot>
        <slot> </slot>
    </b-button>
    
</template>
<script>
export default {
    name: "AppButton",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        block: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: "default",
        },

        size: {
            type: String,
            default: "sm",
        },

        link: {
            type: Boolean,
            description: "Whether button is a link (no borders or background)",
        },
    },

    computed: {
        setVariant:function(){
            if(this.type == 'primary'){
                return 'primary';
            }else if(this.type == 'secondary'){
                return 'secondary';
            }else if(this.type == 'secondary-grey'){
                return 'secondary-grey';
            }else if(this.type == 'tertiary'){
                return 'tertiary';
            }else if(this.type == 'selected'){
                return 'selected';
            }else if(this.type == 'cancel'){
                return 'cancel';
            }else if(this.type == 'outline'){
                return 'outline-primary'
            }else if(this.type == 'outline-danger'){
                return 'outline-danger'
            }else if(this.type == 'students'){
                return 'students'
            }else{
                return 'primary';
            }
        }
    },


    methods: {
        handleClick(evt) {
            this.$emit("click", evt);
        },
    },
};
</script>

