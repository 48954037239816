<template>
     <div>
        <label v-if="label" v-overflow-tooltip class="app-input-top-label">{{label}}</label>
        <validation-provider v-slot="{ errors}" ref="provider" :vid="validatorId" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <b-input-group class="app-input-group" 
                        :class="{'app-input-group-currency': type == 'currency' && !disabled, 
                        'app-input-group-disabled': disabled, 
                        'app-input-group-with-append': isCopyButton,
                        'app-input-group-error': ((errors.length > 0 && errors[0] != '') || currentError) && !disabled}"
                        >
                <b-input-group-prepend 
                            class="d-flex align-items-center app-input-currency-icon" 
                            v-if="type == 'currency'" 
                            :class="{'app-input-currency-icon-filled': update_value != null && update_value.length > 0,
                                'app-input-currency-icon-disabled': disabled}">
                            <!-- <b-icon icon="" class="mr-2"></b-icon>€ -->€
                </b-input-group-prepend>
                <input 
                            ref="field" 
                            v-model="update_value"
                            v-mask="cMask"
                            :id="id" 
                            :name="name"
                            :type="inputType" 
                            :maxLength="cMaxLength" 
                            :disabled="disabled" 
                            :placeholder="placeholder"
                            :autocomplete="autocomplete"
                            :class="{
                                [customInputClass]: true, 'app-local-copy': isCopyButton, 
                                'app-input-filled': update_value != null && update_value.length != '', 
                                'app-input-disabled': disabled, 
                                'app-input-error': ((errors.length > 0 && errors[0] != '') || currentError) && !disabled, 
                                'app-input-currency': type=='currency',
                                'app-input-with-append': isCopyButton,
                                'app-input-currency-disabled': type=='currency' && disabled,
                                }"
                            v-uppercase:[uppercase]

                            @keyup="onKeyUp"
                            @input="onInput" 
                            @change="onChange"
                            @blur="onBlur" 
                            @keypress="onKeypress"
                            @drag="onDrag"
                            @drop="onDrop"
                            @click="onClick"
                />

                <!--v-model="cModel"-->
                <b-input-group-append class="d-flex  app-input-copy-icon   align-items-center" v-if="isCopyButton">
                    <div @click="onCopyClick"><b-icon icon="files" class="mr-1"></b-icon><b-icon icon="arrow-down"></b-icon></div>
                </b-input-group-append>
                
                <b-input-group-append class="d-flex app-input-error-icon align-items-center" v-if="((errors.length > 0 && errors[0] != '' && !disabled) || currentError)  && type != 'hidden'">
                    <b-icon icon="exclamation-circle-fill" class="app-input-error-svg"></b-icon>
                </b-input-group-append>

            </b-input-group>
            <b-spinner v-if="loading" small :class="customSpinnerClass"></b-spinner>
            <small v-if="additionalInfo && !(errors.length > 0 && errors[0] != '')" class=" ml-1 text-muted">{{additionalInfo}}</small>
            <div class="ml-1"><small v-if="errors.length > 0 && errors[0] != '' && !disabled && dontShowError == false" class="text-error" :class="{'app-input-error-ellips': !is_mobile.value}" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small></div>        

        </validation-provider>
    </div>
   
</template>

<script>
import AutoNumeric from "autonumeric";
import AppMasks from "@core/scripts/AppMasks";

export default {
    name: 'AppInput',
    props: {

        id: { type: String },

        value: { },
        type: { type: String, default: "text"},
        name: { type: String },
        placeholder: { type: String },
        timer: { type: Number, default: null },
        disabled: { type: Boolean, default: false },
        mask: { type: String },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        maxLength: { type: Number },
        autocomplete: {type: String, default: "on"},
        loading: {type: Boolean, default: false},
        uppercase: {type: Boolean, default: false},
        label: {type: String},
        additionalInfo: {type: String},
        validatorId:{type: String, default: ''},
        customInputClass:{ type: String, default: "form-control form-control-sm app-input"},
        customSpinnerClass:{ type: String, default: "align-middle app-input-spinner"},
        isCopyButton: { type: Boolean, default: false },
        currentError:{ type: Boolean, default: false},
        focused: {type: Boolean, default: false},
        dontShowError: {type: Boolean, default:false },
    },

    created(){
        this.update_value = this.value;
        if(this.maxLength == null){
            this.customMaxLenght = false;
            this.cMaxLength = 60;
        }
        
    },

    mounted() {

        if(this.type != null && this.type.indexOf('text') == 0){

        }else if(this.type != null && this.type.indexOf('decimal') == 0){

            var place = this.type.split('_');

            if(place.length == 2){//decimal with max length

                var length = '9';

                var options = {
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp,
                                decimalCharacter: AutoNumeric.options.decimalCharacter.comma,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.dot,
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                maximumValue: length.padStart(parseInt(place[1]), 9) + '.99',
                                selectOnFocus: false,
                                showWarnings: AutoNumeric.options.showWarnings.hide

                }

                new AutoNumeric(this.$refs.field, options);

            } else if(place.length == 4 && place[2] == 'places'){

                var length = '9';
                var decimal_places = parseInt(place[3]);
                var max_value = length.padStart(parseInt(place[1]), 9) + '.' + length.padStart(parseInt(place[3]), 9);

                var options = {
                                //allowDecimalPadding: AutoNumeric.options.allowDecimalPadding.never,
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp,
                                decimalCharacter: AutoNumeric.options.decimalCharacter.comma,
                                decimalPlaces: decimal_places,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.dot,
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                maximumValue: max_value,
                                selectOnFocus: false,
                                showWarnings: AutoNumeric.options.showWarnings.hide

                }

                new AutoNumeric(this.$refs.field, options);

            } else{

                var options = {
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp,
                                decimalCharacter: AutoNumeric.options.decimalCharacter.comma,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.dot,
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                selectOnFocus: false,
                                showWarnings: AutoNumeric.options.showWarnings.hide
                            };

                new AutoNumeric(this.$refs.field, options);

            }


        }else if(this.type != null && this.type.indexOf('integer') == 0){

            var place = this.type.split('_');

            if(place.length == 2){//integer with max length
                var length = '9';
                
                var options = {
                                decimalPlaces: AutoNumeric.options.decimalPlaces.none,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.noSeparator,
                                maximumValue: parseInt(length.padStart(parseInt(place[1]), 9)),
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                selectOnFocus: false,
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp
                }

                new AutoNumeric(this.$refs.field, options);

            }else if(place.length == 3){//integer max value

                var options = {
                                decimalPlaces: AutoNumeric.options.decimalPlaces.none,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.noSeparator,
                                maximumValue: parseInt(place[2]),
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                selectOnFocus: false,
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp
                }

                new AutoNumeric(this.$refs.field, options);

            }else{//only integer

                var options = {
                                decimalPlaces: AutoNumeric.options.decimalPlaces.none,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.noSeparator,
                                maximumValue: AutoNumeric.options.maximumValue.oneBillion,
                                minimumValue: AutoNumeric.options.minimumValue.zero,
                                selectOnFocus: false,
                                onInvalidPaste: AutoNumeric.options.onInvalidPaste.clamp,
                                leadingZero: AutoNumeric.options.leadingZero.keep,
                }

                new AutoNumeric(this.$refs.field, options);

            }
            
        }else{
            this.inputType = this.type;
        }

        if(this.focused == true){
            this.setFocus();
        }

    },

    watch:{

        "value":{
            handler: function(val) {
                this.update_value = val;              
            },
        },

    },

    computed: {
        cMaxLength: {
            get() {
                if(this.maxLength != null) return this.maxLength;
                else return this.maxLenghtFixed
            },
            set(value) {
                if(this.maxLength != null) this.$emit('update:maxLength', value);
                else this.maxLenghtFixed = value;
            },
        },
        
        /*cModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },*/

        cMask: {
            get() {

                var returMask = this.mask; 
                if(this.mask != null && this.mask.indexOf('letters') == 0){
                    var eMask = this.mask.split('-');

                    if(eMask.length == 2){
                    
                        var mLength = eMask[1];
                        var mLetters = eMask[0];
                        var rMask = [];


                        if(mLetters != undefined || mLetters != null){
                            var rExp = this.appMasks.getRegexMask(mLetters);
                            
                            for(var i = 0; i < mLength; i++){
                                rMask.push(rExp);
                            }
                           
                            
                            returMask = rMask;
                        }


                    }

                
                }else if(this.mask != null && this.mask == 'BTW'){
                    returMask = this.appMasks.getBTWMask();
                }

                this.changeMaxLenght(returMask);
                
                return returMask
                
                
            }
        },

        
    },

    data() {
        return {
            inputType: "text",
            regexMask: null,
            appMasks: new AppMasks(),
            actualPosition: 0,
            update_value: null,
            customMaxLenght: true,
            maxLenghtFixed:60,
            euro: '\uF637',
            running_timer: null,
        }
    },


  methods: {

    changeMaxLenght(mask){
        if(!this.customMaxLenght && mask != null && mask.length > 0){
                    this.cMaxLength = mask.length;
        }   
    },

    onInput(event) {
      
      this.update_value = event.target.value;
      this.$emit('input', event.target.value);
      
    },
    
    onChange(event) { 
      this.$emit('change', event.target.value);
    },

    onClick(event) { 
      this.$emit('click', event.target.value);
    },

    onBlur(event) {
      this.$emit('blur', event.target.value);
    },

    onKeyUp(event) {
       this.$emit('keyup', event.target.value);
       if(event.key == 'Enter'){
           this.$emit('enter', event.target.value);
       }
       if(this.timer !== null){
            this.lazyTimeOut(event, this.timer);
       }
    },

    lazyTimeOut(event, timer) {

        this.is_change = true;

        this.search = event.target.value;
        if (this.running_timer) {
            clearTimeout(this.running_timer);
            this.running_timer = null;
        }
        if(event.key == 'Enter'){
            this.$emit('custom-lazy', event.target.value);
        }else{
            this.running_timer = setTimeout(() => {
                this.$emit('custom-lazy', event.target.value);
            }, timer);
        }
    },

    onCopyClick(event) {
        this.$emit('copy-click', event.target.value);
    },

    onKeypress($event){
        
        
        if(this.mask != null && this.mask.indexOf('letters') == 0){

             var eMask = this.mask.split('-');

             if(eMask.length == 2){
                   
                var mLetters = eMask[0];
                
                if(mLetters != undefined || mLetters != null){
                    var regexMask = this.appMasks.getRegexMask(mLetters);
                    
                    if(regexMask != null){
                        var res = $event.key.match(regexMask);
                        if (!res) {
                            $event.preventDefault();
                            return false;
                        }
                    }
                }


            }

        }else if(this.mask != null && this.mask == 'BTW'){
            var btwMask = "NL#########B##";

            var regexMask = this.appMasks.getLetterMask(btwMask[$event.target.selectionStart]);

            if(regexMask != null){
                var res = $event.key.match(regexMask);
                if (!res) {
                    $event.preventDefault();
                    return false;
                }
            }

        }else if(this.mask != null){
            
            var regexMask = this.appMasks.getRegexMask(this.mask[$event.target.selectionStart]);
            
            if(regexMask != null){
                var res = $event.key.match(regexMask);
                if (!res) {
                    $event.preventDefault();
                    return false;
                }
            }
        }

    },

    onDrag(event){},

    onDrop(event){
        this.update_value = event.dataTransfer.getData('text');
        this.$emit('input', event.dataTransfer.getData('text'));
    },
    
    setFocus(){
        setTimeout( () => {
                this.$refs.field.focus();
        }, 1);
    }
    
  },
}
</script>
<style scoped>
.app-local-input-spinner{
    position: absolute; 
    top: 32px; 
    right: 22px;
}

.app-local-copy{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}

.app-local-icon{
    border: 1px solid #dee2e6;
    border-left: none;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    color: #8898aa;
}

.app-local-icon:hover{
    cursor: pointer;
    color: #174066;
}

.app-local-email-spinner{
    top: 40px; 
    right: 22px;
}

</style>