import Vue from 'vue'
import dictionary_json from '../assets/json/dictionary.json';
import envVariables from './envVariables';

var envVariablesObj = new envVariables();

export default class translation{

    constructor() {
        // this.dictionary = JSON.parse(localStorage.getItem('dictionary'));
        this.dictionary = JSON.parse(localStorage.getItem('dictionary')) != null ? JSON.parse(localStorage.getItem('dictionary')) : dictionary_json;
        if(this.dictionary == null){
            this.dictionary={};
        }
    }
    changeDictionary(translation) {
        
        this.dictionary = {...translation};
        localStorage.setItem('dictionary', JSON.stringify(this.dictionary));
    };

    addToDictionary(translation) {
        
        this.dictionary = {...this.dictionary, ...translation};
        localStorage.setItem('dictionary', JSON.stringify(this.dictionary));
    };

    updateDictionary(){
        var url = envVariablesObj.getBaseURLforAxios();
        Vue.prototype.$http
            ({url: '/core/translations/getDictionary', baseURL: url, method: 'post', data:{
                language: 'nl',
                app_type: 'EXTERNAL'
            }})
            .then(res => {
                if(res.data != null){
                    this.changeDictionary(res.data);
                }
            })
            .catch(error => {
                console.error(`Error: ${error}`);
            })
            .finally(() =>{
                
            })
    };


    trans(key_name, mergfields = null){

        var object = this.translations.dictionary[key_name];
        if(object == null) {
            return null
        }
        var value = object.value;
        if(value == null) return '';
        if(mergfields != null){       
            for (let key in mergfields)  {
                value = value.replaceAll(':'+key, mergfields[key]);
            };
        }

        if(localStorage.getItem('showTranslationKey') == 'true'){
            return  value + ' (' + key_name + ')';
        }else{
            return  value;
        }
         
    };

    trans(key_name, group_id = null, mergfields = null){
        var object = null;
        if(group_id != null){
            if(this.translations != null){
                var category = this.translations.dictionary[group_id]
            }else{
                var category = this.dictionary[group_id];
            }
            
            if(category == null){
                return null;
            }
            var object = category[key_name];
            
        }

        if(object == null) {
            return null
        }
        var value = object.value;
        if(value == null) return '';
        if(mergfields != null){       
            for (let key in mergfields)  {
                value = value.replaceAll(':'+key, mergfields[key]);
            };
        }

        if(localStorage.getItem('showTranslationKey') == 'true'){
            return  value + ' (' + key_name + ')';
        }else{
            return  value;
        }

    };



   
}