import { render, staticRenderFns } from "./AppMemo.vue?vue&type=template&id=14892e90&scoped=true&"
import script from "./AppMemo.vue?vue&type=script&lang=js&"
export * from "./AppMemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14892e90",
  null
  
)

export default component.exports