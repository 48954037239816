import Vue from 'vue'

// axios
import axios from 'axios'
import router from '../routes/router'
import envVariables from "../util/envVariables";

var envVariablesObj = new envVariables();

const axiosIns = axios.create({
  withCredentials: true,
  baseURL: envVariablesObj.getBaseURLforAxios(),
  headers: {
    Accept: 'application/json',
  }
})

axiosIns.interceptors.request.use(async function (config) {
  config.headers['Start-Time'] = Date.now();

  return config;
});

axiosIns.interceptors.response.use(function (response) {
  
  //obsługa zwrotek innych niz 200 //customy statusy pula od 277-299
  if(response.status === 227){
    router.push({ name: "227-status-page" });
  }else if(response.status === 228){
    router.push({name: "228-status-page"});
  }else if(response.status == 229){
    router.push({name: "229-status-page"});
  }

  return response
}, function (error) {
  //Obsługa błędów
  console.log(error.response);
  if (error.response) {
    
    if (error.response.status === 404 || error.response.status === 400) {

      router.push({ name: "404-error-page" });

    }else if(error.response.status === 500){
      
      router.push({name: "500-error-page"});

    }else if(error.response.status === 452){//File not found - nasz customy status code pula od 452 - 499 jest pusta
      router.push({name: "500-error-page"});
    }
    //Obsługa 401, brakuje obsługi refresh token dla oauth2, obecnie wykorzystujemy personal token,
    //który nie ma resfresh token
    const isAuthenticated = localStorage.getItem('user');
    if (error.response.status === 401 && isAuthenticated) {
        localStorage.removeItem('user');
        router.push({ name: 'Login' , query: { redirect: window.location.hash.substr(1) } })
    }

  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns
export default axiosIns
