export default class envVariables {

    getBaseURLforAxios() {        
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/service/public/app-public/',
        //baseURL: 'http://localhost:8000/app-public/',
        //baseURL: 'https://hn-service.idea3.pl/app-public/',
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/app-public/',
        //baseURL: '10.0.1.7/app-public/',
        if (process.env.NODE_ENV !== 'production') {
            return 'http://localhost:8000/app-public/';

        }
        else {
            //return 'https://happyone-service.happynurse.nl/app-public/'

            //HN TEST
            //return 'https://happyone-service-test.happynurse.nl/app-public/'

            //HN ACC
            //return 'https://happyone-service-acc.happynurse.nl/app-public/'

            //HN PRODUCTION
            return 'https://happyone-service.happynurse.nl/app-public/'

            //azure default domain
            //return 'https://app-tst-weu-backend.azurewebsites.net/app-public/';
            //IDEA 3
            //return 'https://hn-service.idea3.pl/app-public/'
        }
    };

    getBaseURLforMobileAxios() {        
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/service/public/app-public/',
        //baseURL: 'http://localhost:8000/app-public/',
        //baseURL: 'https://hn-service.idea3.pl/app-public/',
        //baseURL: 'https://app-tst-weu-backend.azurewebsites.net/app-public/',
        //baseURL: '10.0.1.7/app-public/',
        if (process.env.NODE_ENV !== 'production') {
            return 'http://localhost:8000/app-mobile/';

        }
        else {
            
            //return 'https://happyone-service.happynurse.nl/app-mobile/'

            //HN TEST
            //return 'https://happyone-service-test.happynurse.nl/app-mobile/'


            //HN ACC
            //return 'https://happyone-service-acc.happynurse.nl/app-mobile/'

            //HN PRODUCTION
            return 'https://happyone-service.happynurse.nl/app-mobile/'

            //azure default domain
            //return 'https://app-tst-weu-backend.azurewebsites.net/app-mobile/';
            //IDEA 3
            //return 'https://hn-service.idea3.pl/app-mobile/'
        }
    };

    getVersion(){

        return '031.01';
    }
  
    getEnvironment(){
        if (process.env.NODE_ENV !== 'production') {
            return 1;
        } else {
            //Test
            //return 1;

            //Pre production
             //return 2;

            //Production
             return 3;
        }
    }

}