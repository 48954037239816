<template>
  <div id="app" v-if="isDict">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from '@axios'
import translation from './util/translations'

export default {
data(){
  return{
    isDict: false
  }
},
  created(){
            axios.post('/core/translations/getDictionary', {
                language: 'nl',
                app_type: 'EXTERNAL'
            })
            .then(res => {
                if(res.data != null){
                    this.translations.changeDictionary(res.data);
                }
            })
            .catch(error => {
                console.error(`Error: ${error}`);
            })
            .finally(() =>{
                this.isDict = true;
            })
  }
}
</script>
