<template>
    <b-row>
         <b-col v-if="customLabel" :cols="labelColumns">
              <slot name='custom-label'></slot>
         </b-col>
         <b-col :cols="customLabel ? 12 - labelColumns : 12">
              <b-form-group :label="label" :content-cols-md="!customLabel && label != null && label.length > 0 ? 12 - labelColumns : 12" :label-cols-md="!customLabel && label != null && label.length > 0 ? labelColumns : 0" label-class="d-flex align-items-center app-input-label">
                   <b-row class="align-items-baseline">
                         <b-col v-for="(column, index) in slots" v-bind:key="index" :cols="column"  v-bind:class="{'pl-1' : setLeftMargin(index), 'pr-1' : setRightMargin(index)}">
                             <slot v-if="slots.length > 1" :name='"col-" + (index + 1)'></slot>                              
                             <slot v-else></slot>                                   
                         </b-col>
                   </b-row>
                   <b-row v-if="customError">
                        <b-col cols="12">
                            <slot name='custom-error'></slot>
                        </b-col>
                   </b-row>                  
              </b-form-group>
         </b-col>
    </b-row>
</template>

<script>

export default {
 name: 'AppRowLeftLabel',
 props: {
    label: { type: String },
    labelColumns: { type: Number, default: 4},
    customError: { type: Boolean, default: false},
    customLabel: {type: Boolean, default: false},
    slots: {
              type: Array, 
              default() { return [12]; }
         },
    isMarginBetween: {type: Boolean, default: true}
 },

 methods: {
    setRightMargin(index) {
        if(this.slots.length > 1 && (this.slots.length - 1 != index) && this.isMarginBetween) {
             return true;
        } else {
             return false;
        }
    },

    setLeftMargin(index) { 
        
        if(this.slots.length > 1 && index > 0 && this.isMarginBetween) {
             return true;
        } else {
             return false;
        }
    }
 },
}
</script>
<style scoped>
.app-local-max-height {
   max-height: 30px;
}
</style>
