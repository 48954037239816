<template>
     <div class="">
        <label v-if="label" class="app-input-top-label">{{label}}</label>
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        <validation-provider v-slot="{ errors}" :vid="validatorId" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
             <b-row class=" flex-nowrap">
                 <b-col cols="12">
                    <b-form-textarea
                                ref="field" 
                                v-model="cModel"
                                v-mask="cMask"
                                :id="id" 
                                :rows="rows"
                                :name="name"
                                :type="input_type" 
                                :maxlength="cMaxLength" 
                                :disabled="disabled" 
                                :placeholder="placeholder" 
                                class="form-control form-control-sm app-memo"
                                :class="{'app-memo-disabled': disabled, 
                                         'app-memo-error': errors.length > 0 && errors[0] != '' && !disabled,
                                         'app-memo-filled': cModel!= null && cModel.length > 0   }"

                                :state="errors.length > 0 && errors[0] != '' && type != 'hidden' && !disabled ? false : null"
                                @keyup="onKeyUp"
                                @input="onInput" 
                                @change="onChange" 
                                @blur="onBlur" 
                    />
                 </b-col>
                </b-row>
                <b-row>
                    <b-col cols="auto" start>
                        <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>
                    </b-col>
                    <b-col end>
                        <small class="d-flex justify-content-end mt-1" v-if="lettersCounter">{{value.length}}/{{maxLength}}</small>
                    </b-col>
                </b-row>
        </validation-provider>
    </div>
   
</template>

<script>
import AutoNumeric from "autonumeric";
import AppMasks from "@core/scripts/AppMasks";

export default {
    name: 'AppMemo',
    props: {

        id: { type: String },

        value: { },
        type: { type: String, default: "text"},
        name: { type: String },
        rows: {type: String},
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        mask: { type: String },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        validatorId:{type: String, default: ''},
        maxLength: { type: Number, default: 500 },
        loading: {type: Boolean,default: false},
        lettersCounter: {type: Boolean, default: false },
        label: {type: String},
        additionalInfo: {type: String},
        
    },

    mounted() {
        if(this.type == 'text') {

        } else if(this.type == 'decimal') {
            var options = {
                                decimalCharacter: AutoNumeric.options.decimalCharacter.comma,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.noSeparator,
                                minimumValue: AutoNumeric.options.minimumValue.zero
                            };

            new AutoNumeric(this.$refs.field, options);

        } else if(this.type == 'integer') {
            
            var options = {
                                allowDecimalPadding: AutoNumeric.options.allowDecimalPadding.never,
                                digitGroupSeparator: AutoNumeric.options.digitGroupSeparator.noSeparator,
                                maximumValue: AutoNumeric.options.maximumValue.oneBillion,
                                minimumValue: AutoNumeric.options.minimumValue.zero
            }

            new AutoNumeric(this.$refs.field, options);

        } else {
            this.inputType = this.type;
        } 
    },

    watch: {
        "validatorCustomMessage": {
             immediate: true,
            handler: function(val) {
                if(this.validatorCustomMessage != undefined && this.validatorCustomMessage != null && this.validatorCustomMessage.length > 0 ){
                     this.$refs.provider.setErrors([this.validatorCustomMessage]);
                }
            },
        },
    },

    computed: {
        cMaxLength: {
            get() {
                return this.maxLength;
            },
            set(value) {
                this.$emit('update:maxLength', value);
            },
        },
        
        cModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

        cMask: {
            get() { 
                if(this.mask != null && this.mask.indexOf('letters') == 0){
                    var eMask = this.mask.split('-');

                    if(eMask.length == 2){
                    
                        var mLength = eMask[1];
                        var mLetters = eMask[0];
                        var rMask = [];


                        if(mLetters != undefined || mLetters != null){
                            var rExp = this.appMasks.getRegexMask(mLetters);
                            
                            for(var i = 0; i < mLength; i++){
                                rMask.push(rExp);
                            }

                            return rMask;
                        }else{
                            return this.mask;
                        }


                    }else{
                        return this.mask;
                    }

                
                }else if(this.mask != null && this.mask == 'BTW'){
                    return this.appMasks.getBTWMask();
                }else{
                    return this.mask;
                }
                
            }
        }
    },

    data() {
        return {
            input_type: "text",
            appMasks: new AppMasks(),
        }
    },


  methods: {
    onInput(event) {
      this.$emit('input', event);
    },
    
    onChange(event) { 
      this.$emit('change', event);
    },

    onBlur(event) {
      this.$emit('blur', event);
    },

    onKeyUp(event) {
       this.$emit('keyup', event);
    },

    onKeypress($event){
        
        if(this.mask != null && this.mask.indexOf('letters') == 0){

             var eMask = this.mask.split('-');

             if(eMask.length == 2){
                   
                var mLetters = eMask[0];
                
                if(mLetters != undefined || mLetters != null){
                    var regexMask = this.appMasks.getRegexMask(mLetters);
                    
                    if(regexMask != null){
                        var res = $event.key.match(regexMask);
                        if (!res) {
                            $event.preventDefault();
                            return false;
                        }
                    }
                }


            }

        }else if(this.mask != null && this.mask == 'BTW'){
            var btwMask = "NL#########B##";

            var regexMask = this.appMasks.getLetterMask(btwMask[$event.target.selectionStart]);

            if(regexMask != null){
                var res = $event.key.match(regexMask);
                if (!res) {
                    $event.preventDefault();
                    return false;
                }
            }

        }else if(this.mask != null){
            
            var regexMask = this.appMasks.getRegexMask(this.mask[$event.target.selectionStart]);
            
            if(regexMask != null){
                var res = $event.key.match(regexMask);
                if (!res) {
                    $event.preventDefault();
                    return false;
                }
            }
        }

        
    }

    
  },
}
</script>
<style scoped>

</style>