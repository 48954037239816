<template>
  <div class="wrapper">
    <div class="environment-banner" v-if="envVariables.getEnvironment() == 2">
      <div class="banner-content">
        <span class="banner-text">
          <template v-for="n in 24">
            {{trans('banner-acc-env', 254)}}<template v-if="n < 24"> • </template>
          </template>
        </span>
        <span class="banner-text">
          •
          <template v-for="n in 24">
            {{trans('banner-acc-env', 254)}}<template v-if="n < 24"> • </template>
          </template>
        </span>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import envVariables from "/src/util/envVariables.js";
  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  //import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      //FadeTransition
    },

    created(){
      
    },

    data(){
      return{
        envVariables: new envVariables()
      }
    },

    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }

  };
</script>
<style lang="scss">
</style>

<style lang="scss" scoped>
.environment-banner {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5365c;
  color: white;
  text-align: center;
  font-weight: bold;
  z-index: 999;
  overflow: hidden;
  user-select: none;
}

.banner-content {
  display: inline-block;
  white-space: nowrap;
  animation: moveText 120s linear infinite;
  user-select: none;
}

.banner-text {
  display: inline-block;
  white-space: nowrap;
  user-select: none;
}

@keyframes moveText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>