<template>
    <div > 
        <b-link :id="'popover' +  _uid" class="ml-1" style="color: #98A2B3 ; cursor:pointer;outline: none !important;">
            <b-icon :icon="filteringObject.values.length > 0 ? 'funnel-fill' : 'funnel'" />
        </b-link>
        
        <b-popover :target="'popover' + _uid" ref="popover" triggers="focus"  no-fade placement="bottomleft"  @hide="closePopover" @show="openPopover" boundary="window">
            <template #title>
                {{label}}
            </template>
           
           <div>
                               
                    <b-card no-body class="mb-2">
                        <b-card-body >
                            <div class="app-fixed-search d-flex align-items-center">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <b-input-group class="input-group-merge" >
                                    
                                        <b-input-group-prepend is-text>
                                            <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                            <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                                        </b-input-group-prepend >

                                        <b-form-input autofocus class="pl-2"  v-model="search" placeholder="Zoeken" style="border:none !important;"/>

                                        <b-input-group-append is-text v-if="search.length > 0">
                                        <b-icon icon="x-circle-fill" class="text-muted" style="cursor: pointer; outline: none !important;" @click="search = ''"></b-icon>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <hr class="app-search-modal-separator">
                                <b-list-group flush style="max-height:300px; min-width:124px; overflow-y:auto;">
                                    <b-list-group-item class="d-flex  align-items-center" @keyup.enter="trigger" action v-for="item in filteredList" :key="item.value" :value="item" tabindex="0" style="cursor:pointer; padding:4px 4px; outline: none !important;" @click.prevent="selectItem(item)">
                                        <b-form-checkbox tabindex="0" v-model="item.selected" value="1" unchecked-value="0" size="sm" @change="refreshCounters" :class="item.selected == 1 ? 'custom-checkbox-checked' : ''">
                                            <span class="app-documents-item">{{item.name}}</span>
                                        </b-form-checkbox>
                                    </b-list-group-item>
                                </b-list-group>
                        </b-card-body>
                    </b-card> 
                    <b-row class="align-items-center">
                        <b-col cols="auto" class="mr-auto">
                            <b-link v-if="checkedCount > 0" class="ml-1" style="color: #98A2B3; cursor:pointer;" @click="clearFilter">Verwijderen ({{ this.checkedCount }})</b-link>
                        </b-col>
                        <b-col cols="auto">
                            <app-button type="primary" ref="setFilters" @click="setFilter" >Toepassen</app-button>
                        </b-col>
                    </b-row>
            </div>
            
           </b-popover>
           
    </div>  
</template>
<script>

import axios from "@axios";
export default {
    props: ["label", "filteringObject", "fieldName"],

    computed: {    
        filteredList() {
            return this.items.filter(item => {
                return item.name.toLowerCase().includes(this.search.toLowerCase())
            })       
        },
    },

    data() {
        return {
            loading: false,
            search: "",
            items: this.filteringObject.customList ? this.filteringObject.customList:[],
            checkedCount:0,
        };
    },
    
    methods: {

        trigger () {
    	    this.setFilter();
        },

        selectItem(item) {          
            
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';
            }

            this.$set(this.items, index, item);
        },

         refreshCounters() {            
             this.checkedCount = this.items.filter(item => {return item.selected === "1"}).length;
         },
         getData() {
             
            this.loading = true;
            axios
                .post("core/select-item/getList", {
                    type: this.filteringObject.function,
                    parameters: JSON.stringify(this.filteringObject.parameters),
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                   
                     this.items.forEach((item, index) => {
                        var exist = this.filteringObject.values.filter(element => {return element === item.value}).length;
                    
                        if (exist > 0) {
                            
                            item.selected = "1";
                        } else {
                            item.selected = "0";
                        }
                    });

                    this.refreshCounters();


                   this.loading = false;
                });
        },
       
        openPopover() {
            
            if(this.filteringObject.getList == true){//ponowne pobieranie w przypadku listy shift i assignment
                this.getData();
            }else if (this.items.length > 0) {
                if (this.filteringObject.values.length == 0) {
                    this.items.forEach((item, index) => {
                        item.selected = "0" ;
                    }); 
                    this.refreshCounters();          
                }
            } else {
                this.getData();
            }  
                
                
        },

        closePopover() {
            this.refreshCounters();
        },

        clearFilter() {
            var nFilteringObject = this.filteringObject;

            this.items.forEach((item, index) => {
                item.selected = "0" ;
            });          
            
            this.checkChanged();

            nFilteringObject.values = [];
            this.refreshCounters();
            this.$refs.popover.$emit('close');
        },

        setFilter() {
            var nFilteringObject = this.filteringObject;
            var ids = [];
            var selectedElements = this.items.filter(item => {return item.selected === "1"});
            selectedElements.forEach((item, index) => {
                ids.push(item.value);
            });  

            this.checkChanged();
          

            nFilteringObject.values = ids;
            this.refreshCounters();
            this.$refs.popover.$emit('close');
        },

        checkChanged() {
            var nFilteringObject = this.filteringObject;
            var ids = [];
            var selectedElements = this.items.filter(item => {return item.selected === "1"});
            selectedElements.forEach((item, index) => {
                ids.push(item.value);
            });  
            if (JSON.stringify(this.filteringObject.values) !== JSON.stringify(ids)) {
                 nFilteringObject.changed++;
            }
        }


    },

};
</script>

<style scoped>
.app-spinner-size {
    max-width:20px;
    max-height:20px;
}

.popover .arrow {
    display: none !important;
}

.custom-control:focus {
    outline: none !important;
}

.input-group-text {
    border: none;
}

.card-body {
    padding: 4px;
}
.input-group-text .b-icon{
    overflow: visible;
}


.container {
             width: 100%;
             height: 100%;
             background-color: green;
             display: flex;
             justify-content: center;
             align-items: center;
         }
         .child-div {
             height: 100%;
             background-color: red;
             margin: 0 20px;
         }
</style>