import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import MainLayout from '@/views/Layout/MainLayout.vue';
import Page227 from '@/views/227Page.vue';
import Page228 from '@/views/228Page.vue';
import Page229 from '@/views/229Page.vue';
import Page404 from '@/views/404Page.vue';
import Page500 from '@/views/500Page.vue';

let authPages = {
  path: '*',
  component: AuthLayout,
  name: 'Authentication',
  children: [    
    { 
      path: '*',
      name: '404-error-page',
      component: Page404,
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: "/blocked-form",
      name: "227-status-page",
      component: Page227,
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: "/completed-form",
      name: "228-status-page",
      component: Page228,
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: "/signed-document",
      name: "229-status-page",
      component: Page229,
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: "/500-error",
      name: "500-error-page",
      component: Page500,
      meta: {
        stylesheet: 'primary'
      }
    }
  ]
};

let peoplePages = {
  path: '*',
  component: MainLayout,
  name: 'Main layout people',
  children: [    
    { 
      path: '/people/data-wizard/:token([0-9a-z]{13})',
      name: 'people-data-wizard',
      component: () => import('@app/People/DataWizard/PeopleDataWizard.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: '/people/document-sign/:token([0-9a-z]{13})',
      name: 'people-document-sign',
      component: () => import('@app/People/DocumentSign/PeopleDocumentSign.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@app/People/ResetPassword/ResetPassword.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: '/rp',
      name: 'ResetPassword',
      component: () => import('@app/People/ResetPassword/ResetPassword.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },

    {
      path: '/download/:token',
      name: 'download',
      component: () => import('@app/Download/DocumentDowload.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },

    { 
        path: '/people/risico-form/:token([0-9a-z]{13})',
        name: 'people-risico-form',
        component: () => import('@app/People/RisicoForm/PeopleRisicoForm.vue'),
        meta: {
          stylesheet: 'primary'
        }
    },
    
    { 
        path: '/people/vog-form/:token([0-9a-z]{13})',
        name: 'people-vog-form',
        component: () => import('@app/People/VogForm/PeopleVogForm.vue'),
        meta: {
          stylesheet: 'primary'
        }
    },
  ]
};

let studentsPages = {
    path: '*',
    component: MainLayout,
    name: 'Main layout students',
    children: [    
      { 
        path: '/students/data-wizard/:token([0-9a-z]{13})',
        name: 'students-data-wizard',
        component: () => import('@app/Students/DataWizard/StudentsDataWizard.vue'),
        meta: {
          stylesheet: 'student'
        }
      },
      {
        path: '/students/document-sign/:token([0-9a-z]{13})',
        name: 'students-document-sign',
        component: () => import('@app/Students/DocumentSign/StudentsDocumentSign.vue'),
        meta: {
          stylesheet: 'student'
        }
      },
      { 
        path: '/students/risico-form/:token([0-9a-z]{13})',
        name: 'students-risico-form',
        component: () => import('@app/Students/RisicoForm/StudentsRisicoForm.vue'),
        meta: {
          stylesheet: 'student'
        }
      },
      { 
        path: '/students/vog-form/:token([0-9a-z]{13})',
        name: 'students-vog-form',
        component: () => import('@app/Students/VogForm/StudentsVogForm.vue'),
        meta: {
          stylesheet: 'student'
        }
      },
    ]
  };

let clientPages = {
  path: '*',
  component: MainLayout,
  name: 'Main layout client',
  children: [    
    { 
      path: '/client/data-wizard/:token([0-9a-z]{13})',
      name: 'client-data-wizard',
      component: () => import('@app/Clients/DataWizard/ClientsDataWizard.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: '/client/document-sign/:token([0-9a-z]{13})',
      name: 'client-document-sign',
      component: () => import('@app/Clients/DocumentSign/ClientsDocumentSign.vue'),
      meta: {
        stylesheet: 'primary'
      }
    },
    {
      path: '/client/optin-wizard/:token([0-9a-z]{13})',
      name: 'client-optin-wizard',
      component: () => import('@app/Clients/OptInWizard/ClientsOptInWizard.vue'),
      meta: {
        stylesheet: 'primary'
      }
    }
  ]
};

const routes = [
   peoplePages,
   studentsPages,
   clientPages,
   authPages
];

export default routes;
