import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import VueMask from 'v-mask';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueSignature from "vue-signature-pad";

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

// plugin setup
Vue.use(VueMask);
Vue.use(DashboardPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueSignature);

Vue.prototype.is_mobile = Vue.observable({value: screen.width < 667 || (screen.width < 1340 && screen.width > 767 ) ? true : false, small: screen.width < 346, documents: screen.width < 1400});
window.addEventListener('resize', () => {
  Vue.prototype.is_mobile.value = window.innerWidth < 667 || (window.innerWidth < 1340 && window.innerWidth > 767 ) ? true : false;
  Vue.prototype.is_mobile.documents = window.innerWidth < 1400 ? true : false;
  Vue.prototype.is_mobile.small = window.innerWidth < 346;
});

Vue.use({
  install: function(Vue, options) {
      Object.defineProperty(Vue.prototype, "uniqId", {
          get: function uniqId() {
              if ('_uid' in this) {
                 return this._uid;
              }
              throw new Error("_uid property does not exist");
          }
      });
  }
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router
});
